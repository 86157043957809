import React, {useState, useEffect} from 'react'
import logo from "../Assets/1x/1.png";
import bg from '../Assets/Page 3/1x/28.png'
import thanks from '../Assets/page 2/1x/33.png'
export default function EnglishThankyou() {
    const [width, setWidth] = useState(window.innerWidth);
    const [height , setHeight] = useState(window.innerHeight);
    useEffect(() => {
        console.log(localStorage.getItem('comment6'));
        console.log(localStorage.getItem('comment7'));
        localStorage.getItem('comment7');
      const handleResize = () => {
        const img = localStorage.getItem('combinedImage')
       console.log('new image is : ', img);
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
        console.log("Width and Height are : ",width, height);
      };
    
      window.addEventListener('resize', handleResize);
      //window.addEventListener('resize',handleResize)
      console.log("Width and Height are : ",width, height);
      return () => {
        window.removeEventListener('resize', handleResize);
        console.log("Width and Height  : ",width, height);
      };
    }, []);
  return (
    <div style={{
        backgroundColor: "ButtonShadow",
        display: "flex",
        flexDirection: "row",
        position: "relative",
        alignItems: "center",
        backgroundSize: "cover",
        minHeight: "100vh",
        justifyContent: "center",
        backgroundImage: `url(${bg})`
      }}>
          <img style={{position:'absolute', width:width/2,marginTop:-height/1.3 ,}} src={logo}
          ></img>
          <img style={{position:'absolute',marginTop:-height/19.5 , width:width/1.2,}} src={thanks}
          ></img>
          {/* <img style={{position:'absolute',marginTop:height/3.5 , width:width/1.2,}} src={textImg}
          ></img> */}
      </div>
  )
}
