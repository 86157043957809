export const nationalitiesInArabic = [
    "سعودي",
    "إماراتي",
    "بحريني",
    "قطري",
    "كويتي",
    "عماني",
    "أفغاني",
    "بنغلادشي",
    "ألباني",
    "جزائري",
    "أمريكي",
    "جزر أمريكية",
    "ساموي أمريكي",
    "أندوري",
    "أنغولي",
    "أنغيلاي",
    "أنتاركتيكي",
    "أنتيغوي وباربودي",
    "أرجنتيني",
    "أرميني",
    "أوروبي",
    "أسترالي",
    "نمساوي",
    "أذربيجاني",
    "باهامي",
    "بنغلاديشي",
    "بربادوسي",
    "بيلاروسي",
    "بلجيكي",
    "بيليزي",
    "بنين",
    "برمودي",
    "بوتاني",
    "بوليفي",
    "بوسني",
    "برازيلي",
    "بريطاني",
    "بروني",
    "بلغاري",
    "بوركينابي",
    "بوروندي",
    "كمبودي",
    "كاميروني",
    "كندي",
    "كيب فردي",
    "كايماني",
    "أفريقي مركزي",
    "تشادي",
    "تشيلي",
    "صيني",
    "جزيرة الكريسماس",
    "كولومبي",
    "جزر القمر",
    "كونغولي",
    "كوستاريكي",
    "كرواتي",
    "كوبي",
    "قبرصي",
    "تشيكي",
    "دنماركي",
    "جيبوتي",
    "دومينيكي",
    "هولندي",
    "تيموري شرقي",
    "إكوادوري",
    "مصري",
    "غيني استوائي",
    "إريتري",
    "إستوني",
    "إثيوبي",
    "فولكلاندي",
    "جزر فارو",
    "فيجي",
    "فلبيني",
    "فنلندي",
    "فرنسي",
    "بولينيزي فرنسي",
    "غابوني",
    "غامبي",
    "جورجي",
    "ألماني",
    "غاني",
    "جبل طارقي",
    "يوناني",
    "غرينلاندي",
    "غرينادي",
    "غوادلوب",
    "غوامي",
    "غواتيمالي",
    "جيرسي",
    "غوياني",
    "غينيا بيساوي",
    "غيني",
    "غياني",
    "هايتي",
    "هندوراسي",
    "هونغ كونغي",
    "هنغاري",
    "آيسلندي",
    "كيريباسي",
    "هندي",
    "إندونيسي",
    "إيراني",
    "عراقي",
    "إيرلندي",
    "إسرائيلي",
    "إيطالي",
    "ساحلي",
    "جامايكي",
    "ياباني",
    "جيرسي",
    "أردني",
    "كازاخستاني",
    "كيني",
    "قرغيزي",
    "نيفيزي",
    "كوسوفي",
    "لاوسي",
    "لاتفي",
    "لبناني",
    "ليبيري",
    "ليبي",
    "ليختنشتايني",
    "ليتواني",
    "لوكسمبورغي",
    "ماكاوي",
    "مقدوني",
    "ماوريني",
    "مدغشقري",
    "ملاوي",
    "ماليزي",
    "مالديفي",
    "مالي",
    "مالطي",
    "مانكسي",
    "مارشالي",
    "موريتاني",
    "موريشي",
    "مكسيكي",
    "مايكرونيزي",
    "مولدوفي",
    "موناكي",
    "منغولي",
    "مونتينيغري",
    "مغربي",
    "باسوثي",
    "بوتسواني",
    "موزمبيقي",
    "ميانماري",
    "ناميبي",
    "نوري",
    "نيبالي",
    "كاليدوني جديد",
    "نيوزيلندي",
    "نيكاراغوي",
    "نيجيري",
    "نيجيري",
    "نيوي",
    "ني فانواتو",
    "نورفولكي",
    "كوري شمالي",
    "نرويجي",
    "باكستاني",
    "بلاوي",
    "فلسطيني",
    "بنمي",
    "بابوا نيو غيني",
    "باراغوايي",
    "بيروفي",
    "بيتكيرن",
    "بولندي",
    "برتغالي",
    "بورتوريكي",
    "رينيوني",
    "روماني",
    "روسي",
    "رواندي",
    "صحراوي",
    "سانت لوسي",
    "سانت هيليني",
    "سانت بيير",
    "سلفادوري",
    "سانت فنسنت",
    "ساماريني",
    "سيراليوني",
    "سيراليوني",
    "سيراليوني",
    "سنغافوري",
    "سلوفاكي",
    "سلوفيني",
    "جزائري",
    "سويسري",
    "سوري",
    "طاجيكي",
    "تايواني",
    "تنزاني",
    "تايلاندي",
    "توجولي",
    "توكيلاوي",
    "تونغي",
    "ترينيدادي",
    "تونيزي",
    "تركي",
    "تركماني",
    "كاكوسي",
    "توكلوي",
    "أوغندي",
    "أوكراني",
    "أوروغواي",
    "أوزبكي",
    "فنزويلي",
    "فيتنامي",
    "جزر فيرجن",
    "يامني",
    "زامبي",
    "زيمبابوي"
];
