import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import io from "socket.io-client"; // Import Socket.IO client
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { nationalities } from "../common/nationalities";
import bg from "../Assets/page 2/1x/bg.png";
import box from "../Assets/page 2/1x/box.png";
import spin from "../Assets/page 2/1x/13.png";
import logo from "../Assets/1x/1.png";
import { config } from "../common/baseURL";

const SERVER_URL = "https://786c-94-57-168-212.ngrok-free.app";

const socket = io(config.baseURL, {
  transports: ["websocket"],
  secure: true,
  rejectUnauthorized: false,
});

export default function Signup() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [nationality, setNationality] = useState(null);
  const [name, setName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [emailError, setEmailError] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [validEmail, setValidEmail] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width, height]);

  const handleSubmitClick = () => {
    if (!email || !mobileNumber || !nationality || !name) {
      toast.error("Please fill all the details");
      return;
    } else if (!validEmail) {
      toast.error("Enter valid email");
      return;
    } else {
      // toast.success('Form submitted!');

      const registrationData = {
        name,
        email,
        nationality: nationality.label,
        mobileNumber,
      };

      socket.emit("lazuGame", registrationData);

      setEmail("");
      setName("");
      setNationality(null);
      setMobileNumber("");
      navigate("/enth");
    }
  };

  const handleEmailChange = (event) => {
    const emailInput = event.target.value;
    setEmail(emailInput);

    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailInput);
    setValidEmail(isValid);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleMobileNumberChange = (event) => {
    const value = event.target.value.replace(/\D/g, "").slice(0, 11);
    setMobileNumber(value);
  };

  const nationalityOptions = nationalities.map((nat) => ({
    label: nat,
    value: nat.toLowerCase().replace(/\s+/g, "_"),
  }));

  const handleNationalityChange = (selectedOption) => {
    setNationality(selectedOption);
  };
  const emailValidationClass = emailError
    ? "is-invalid"
    : email && !emailError
    ? "is-valid"
    : "";
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        alignItems: "center",
        backgroundSize: "cover",
        minHeight: "100vh",
        justifyContent: "center",
        // backgroundColor: "lightblue",
        backgroundImage: `url(${bg})`,
      }}
    >
      <img
        style={{
          position: "absolute",
          width: width / 2,
          marginTop: -height / 1.2,
        }}
        src={logo}
      ></img>
      <div
        style={{
          backgroundImage: `url(${box})`,
          backgroundSize: "cover",
          padding: 45,
          position: "absolute",
          borderRadius: 33,
          minHeight: "50vh",
          minWidth: "90vw",
          //  width: "90%",
          //   height: "62%",
          //  width: "100%",
          //  height: '60%',
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          //backgroundColor:"grey"
        }}
      >
        <Form style={{ display: "flex", flexDirection: "column" }}>
          <Form.Group className="mb-2" controlId="formBasicName">
            <Form.Label className="mb-0" style={{ color: "white" }}>
              Name
            </Form.Label>
            <Form.Control
              type="text"
              //placeholder="Name..."
              value={name}
              onChange={handleNameChange}
              style={{ borderRadius: 10, backgroundColor: "grey" }}
            />
          </Form.Group>
          <Form.Group className="mb-2" controlId="formBasicEmail">
            <Form.Label className="mb-0" style={{ color: "white" }}>
              Email
            </Form.Label>
            <Form.Control
              type="email"
              //placeholder="Email..."
              value={email}
              onChange={handleEmailChange}
             // className={emailValidationClass} // Apply validation class
              required
              style={{ borderRadius: 10, backgroundColor: "grey" }}
            />
            {/* {emailError && <p style={{ color: 'red' }}>{emailError}</p>} */}
          </Form.Group>
          <Form.Group className="mb-2" controlId="formBasicMobileNumber">
            <Form.Label className="mb-0" style={{ color: "white" }}>
              Phone Number
            </Form.Label>
            <Form.Control
              type="tel"
              //placeholder="Mobile Number..."
              value={mobileNumber}
              onChange={handleMobileNumberChange}
              style={{ borderRadius: 10, backgroundColor: "grey" }}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicNationality">
            <Form.Label className="mb-0" style={{ color: "white" }}>
              Nationality
            </Form.Label>
            {/* <Select
              options={nationalityOptions}
              value={nationality}
              onChange={handleNationalityChange}
              //placeholder="Select Nationality"
              isSearchable={true}
              styles={{
               
                control: (base) => ({
                  ...base,
                  border: '0 !important',
                  color:'grey',
                  backgroundColor:'grey',
                  borderRadius: 12,
                  boxShadow: '0 !important',
                  textAlign: 'right',
                  '&:hover': {
                    border: '0 !important'
                  },
                  option: (base, { isSelected }) => ({
                    ...base,
                   // color: isSelected ? 'red' : base.color,  // Color for the selected option in the dropdown
                    backgroundColor: isSelected ? 'grey' : base.backgroundColor,
                  }),
                }),
              }}
            /> */}
            <Select
              options={nationalityOptions}
              value={nationality}
              onChange={handleNationalityChange}
              isSearchable={true}
                placeholder=""
              styles={{
                control: (base) => ({
                  ...base,
                  border: "0 !important",
                  backgroundColor:'grey',
                  borderRadius: 12,
                  boxShadow: "0 !important",
                  //textAlign: "right",
                  "&:hover": {
                    border: "0 !important",
                  },
                }),
                option: (base, { isSelected }) => ({
                  ...base,
                  backgroundColor: isSelected ? "grey" : base.backgroundColor,
                }),
              }}
            />
          </Form.Group>
        </Form>
        <img
          src={spin}
          style={{
            width: width / 3,
            height: height / 23,
            marginTop: height / 42,
          }}
          onClick={handleSubmitClick}
          alt="Submit Button"
        />
      </div>

      {/* <button
        style={styles.button}
        
      >
        Submit
      </button> */}
    </div>
  );
}

const styles = {
  button: {
    border: 0,
    width: "40%",
  },
};
