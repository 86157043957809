// import React from 'react';
// import { Link } from 'react-router-dom';
// import './homepage.css'; // External CSS for styles

// const FirstPage = () => {
//   return (
//     <div className="home-page">
//       <div className="background-image"></div>

//       <img 
//         className="logo" 
//        // src="path-to-logo.png" 
//         alt="Logo" 
//       />

//       <div className="button-container">
//         <Link to="/screen1">
//           <button className="nav-button">Go to Screen 1</button>
//         </Link>
//         <Link to="/screen2">
//           <button className="nav-button">Go to Screen 2</button>
//         </Link>
//       </div>
//     </div>
//   );
// }

// export default FirstPage;


import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import enbutton from '../Assets/1x/11.png'
import arbutton from '../Assets/1x/12.png'
import bg from '../Assets/1x/2.png';
import logo from '../Assets/1x/1.png';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
    const navigate = useNavigate();
    const [width, setWidth] = useState(window.innerWidth);
    const [height , setHeight] = useState(window.innerHeight);
    useEffect(() => {
        console.log(localStorage.getItem('comment6'));
        console.log(localStorage.getItem('comment7'));
        localStorage.getItem('comment7');
      const handleResize = () => {
        const img = localStorage.getItem('combinedImage')
       console.log('new image is : ', img);
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
        console.log("Width and Height are : ",width, height);
      };
    
      window.addEventListener('resize', handleResize);
      //window.addEventListener('resize',handleResize)
      console.log("Width and Height are : ",width, height);
      return () => {
        window.removeEventListener('resize', handleResize);
        console.log("Width and Height  : ",width, height);
      };
    }, []);
    const handleEnglishClick=()=>{
        navigate('/english')
    }
    const handleArabicClick=()=>{
        navigate('/arabic')
    }

  return (
    <div style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        alignItems: "center",
        backgroundSize: "cover",
        minHeight: "100vh",
        justifyContent: "center",
        backgroundImage: `url(${bg})`}}>
      <div style={styles.backgroundImage}></div>

      {/* <img 
        style={styles.logo} 
        src= // Replace with your logo image path
        alt="Logo" 
      /> */}
 <img style={{position:'absolute', width:width/2,marginTop:-height/2 ,}} src={logo}></img>
<img src={enbutton}
        style={{width: width/3, height: height/23, marginTop: -height/9}}
        onClick={handleEnglishClick}
        alt="Submit Button"
      />
   
   <img src={arbutton}
        style={{width: width/3, height: height/23, marginTop: height/42}}
        onClick={handleArabicClick}
        alt="Submit Button"
      />
    </div>
  );
};


const styles = {
    homePage: {
      position: 'relative',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    backgroundImage: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundImage: 'url("../Assets/1x/Asset 10.png")', // Replace with your background image path
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      zIndex: -1, 
    },
    logo: {
      width: '150px',
      marginBottom: '50px',
    },
    buttonContainer: {
      display: 'flex',
      gap: '20px',
      flexDirection:'column'
    },
    navButton: {
      padding: '15px 30px',
      fontSize: '16px',
      backgroundColor: '#4CAF50',
      color: 'white',
      border: 'none',
      cursor: 'pointer',
      borderRadius: '5px',
      transition: 'background-color 0.3s ease',
    },
    navButtonHover: {
      backgroundColor: '#45a049',
    },
  };

export default HomePage;
