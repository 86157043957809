import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Registration from './Components/Registration';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer, Zoom } from 'react-toastify';
import Chat from './Components/socket';
import SetUrl from './Components/SetUrl';
import FirstPage from './Components/FirstPage';
import ArabicRegistration from './Components/ArabicRegisteration'
import EnglishThankyou from './Components/EnglishThankyou';
import ArabicThankyou from './Components/ArabicThankyou';
function App() {
  return (
    <div>
      <Router>
      <ToastContainer className='root' position="top-right" style={{}}></ToastContainer>
        <Routes>
      <Route path='/english' element={<Registration></Registration>}/>
      <Route path='/chat' element={<Chat></Chat>}/>
      <Route path='/ngrokurl' element={<SetUrl></SetUrl>}/>
      <Route path='/' element={<FirstPage></FirstPage>}/>
      <Route path='/arabic' element={<ArabicRegistration></ArabicRegistration>}/>
      <Route path='/enth' element={<EnglishThankyou></EnglishThankyou>}/>
      <Route path='/arth' element={<ArabicThankyou></ArabicThankyou>}/>
        </Routes>
      </Router>
   
    </div>
  );
}

export default App;
