import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { config } from '../common/baseURL';

// Replace this URL with the actual ngrok URL or your server URL
const SERVER_URL = 'https://786c-94-57-168-212.ngrok-free.app'; // Replace with your actual ngrok URL

const socket = io(config.baseURL, {
    transports: ['websocket'], // Use WebSocket, fallback to polling if needed
    secure: true,              // Ensure secure connection (especially if using ngrok with HTTPS)
    rejectUnauthorized: false  // Bypass certificate validation for self-signed certificates (dev purpose)
});

const Chat = () => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    // Listen for messages from the server
    socket.on('lazuGame', (msg) => {
      setMessages((prevMessages) => [...prevMessages, msg]);
    });

    // Error handling and connection troubleshooting
    socket.on('connect_error', (err) => {
      console.error('Connection error:', err);
    });

    socket.on('connect_timeout', () => {
      console.error('Connection timeout');
    });

    socket.on('error', (err) => {
      console.error('Socket error:', err);
    });

    socket.on('disconnect', (reason) => {
      console.warn('Socket disconnected:', reason);
    });

    socket.on('reconnect_attempt', (attemptNumber) => {
      console.warn(`Reconnect attempt: ${attemptNumber}`);
    });

    // Cleanup on component unmount
    return () => {
      socket.off('lazuGame');
      socket.off('connect_error');
      socket.off('connect_timeout');
      socket.off('error');
      socket.off('disconnect');
      socket.off('reconnect_attempt');
    };
  }, []);

  // Scroll to the bottom of the messages list when new messages arrive
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim()) {
      // Emit the message to the server
      socket.emit('lazuGame', input);
      setInput('');
    }
  };

  return (
    <div>
      <ul id="messages">
        {messages.map((msg, index) => (
          <li key={index} style={{ background: index % 2 === 0 ? '#efefef' : 'transparent' }}>
            {msg}
          </li>
        ))}
        <div ref={messagesEndRef} />
      </ul>
      <form id="form" onSubmit={handleSubmit}>
        <input
          id="input"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          autoComplete="off"
          placeholder="Type a message..."
        />
        <button type="submit">Send</button>
      </form>
    </div>
  );
};

export default Chat;
