import React, { useState } from "react";
import { config } from "../common/baseURL";

export default function SetUrl() {
    const [input, setInput] = useState('');

    const handleSaveButton = () => {
        config.baseURL = input;
        console.log('base url is', config.baseURL);
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                alignItems: "center",
                backgroundSize: "cover",
                minHeight: "100vh",
                justifyContent: "center",
            }}
        >
           
                <input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    style={{ width: "80%"}}
                    placeholder="ngrok url..."
                />
        
            <button
                style={{ margin: 30, padding: 7, borderColor: "grey", width: "40%" }}
                onClick={handleSaveButton}
            >
                Save
            </button>
        </div>
    );
}
