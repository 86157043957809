export const nationalities = [
    "Saudi Arabian",
                "Emirati",
                "Bahraini",
                "Qatari",
                "Kuwaiti",
                "Omani",
                "Afghan",
                "landish",
                "Albanian",
                "Algerian",
                "American",
                "American Islander",
                "American Samoan",
                "Andorran",
                "Angolan",
                "Anguillian",
                "Antarctican",
                "Antiguan, Barbudan",
                "Argentine",
                "Armenian",
                "Aruban",
                "Australian",
                "Austrian",
                "Azerbaijani",
                "Bahamian",
                "Bangladeshi",
                "Barbadian",
                "Belarusian",
                "Belgian",
                "Belizean",
                "Beninese",
                "Bermudian",
                "Bhutanese",
                "Bolivian",
                "Bosnian",
                "Herzegovinian",
                "Brazilian",
                "British",
                "Bruneian",
                "Bulgarian",
                "Burkinabe",
                "Burundian",
                "Cambodian",
                "Cameroonian",
                "Canadian",
                "Cape Verdian",
                "Caymanian",
                "Central African",
                "Chadian",
                "Chilean",
                "Chinese",
                "Christmas Islander",
                "Cocos Islander",
                "Colombian",
                "Comoran",
                "Congolese",
                "Congolese",
                "Cook Islander",
                "Costa Rican",
                "Croatian",
                "Cuban",
                "Curaaoan",
                "Cypriot",
                "Czech",
                "Danish",
                "Djibouti",
                "Dominican",
                "Dominican",
                "Dutch",
                "East Timorese",
                "Ecuadorean",
                "Egyptian",
                "Equatorial Guinean",
                "Eritrean",
                "Estonian",
                "Ethiopian",
                "Falkland Islander",
                "Faroese",
                "Fijian",
                "Filipino",
                "Finnish",
                "French",
                "French Polynesian",
                "Gabonese",
                "Gambian",
                "Georgian",
                "German",
                "Ghanaian",
                "Gibraltar",
                "Greek",
                "Greenlandic",
                "Grenadian",
                "Guadeloupian",
                "Guamanian",
                "Guatemalan",
                "Guernseymen",
                "Guianan",
                "Guinea-Bissauan",
                "Guinean",
                "Guyanese",
                "Haitian",
                "Heard and McDonald Islander",
                "Honduran",
                "Hong Konger",
                "Hungarian",
                "Icelander",
                "I-Kiribati",
                "Indian",
                "Indonesian",
                "Iranian",
                "Iraqi",
                "Irish",
                "Israeli",
                "Italian",
                "Ivorian",
                "Jamaican",
                "Japanese",
                "Jerseymen",
                "Jordanian",
                "Kazakhstani",
                "Kenyan",
                "Kirghiz",
                "Kittitian or Nevisian",
                "Kosovar",
                "Laotian",
                "Latvian",
                "Lebanese",
                "Liberian",
                "Libyan",
                "Liechtensteiner",
                "Lithuanian",
                "Luxembourger",
                "Macanese",
                "Macedonian",
                "Mahoran",
                "Malagasy",
                "Malawian",
                "Malaysian",
                "Maldivan",
                "Malian",
                "Maltese",
                "Manx",
                "Mariana",
                "Marshallese",
                "Martinican",
                "Mauritanian",
                "Mauritian",
                "Mexican",
                "Micronesian",
                "Moldovan",
                "Monegasque",
                "Mongolian",
                "Montenegrin",
                "Montserratian",
                "Moroccan",
                "Mosotho",
                "Motswana",
                "Mozambican",
                "Myanmar",
                "Namibian",
                "Nauruan",
                "Nepalese",
                "New Caledonian",
                "New Zealander",
                "Nicaraguan",
                "Nigerian",
                "Nigerien",
                "Niuean",
                "Ni-Vanuatu",
                "Norfolk Islander",
                "North Korean",
                "Norwegian",
                "Pakistani",
                "Palauan",
                "Palestinian",
                "Panamanian",
                "Papua New Guinean",
                "Paraguayan",
                "Peruvian",
                "Pitcairn Islander",
                "Polish",
                "Portuguese",
                "Puerto Rican",
                "Runionese",
                "Romanian",
                "Russian",
                "Rwandan",
                "Sahrawi",
                "Saint Barthlemy Islander",
                "Saint Helenian",
                "Saint Lucian",
                "Saint Martin Islander",
                "Saint-Pierrais",
                "Saint Vincentian",
                "Salvadoran",
                "Sammarinese",
                "Samoan",
                "Sao Tomean",
                "Senegalese",
                "Serbian",
                "Seychellois",
                "Sierra Leonean",
                "Singaporean",
                "Slovak",
                "Slovene",
                "Solomon Islander",
                "Somali",
                "South African",
                "South Georgian South Sandwich Islander",
                "South Korean",
                "South Sudanese",
                "Spanish",
                "Sri Lankan",
                "St. Maartener",
                "Sudanese",
                "Surinamer",
                "Swazi",
                "Swedish",
                "Swiss",
                "Syrian",
                "Tadzhik",
                "Taiwanese",
                "Tanzanian",
                "Thai",
                "Togolese",
                "Tokelauan",
                "Tongan",
                "Trinidadian",
                "Tunisian",
                "Turkish",
                "Turkmen",
                "Turks and Caicos Islander",
                "Tuvaluan",
                "Ugandan",
                "Ukrainian",
                "Uruguayan",
                "Uzbekistani",
                "Vatican",
                "Venezuelan",
                "Vietnamese",
                "Virgin Islander",
                "Virgin Islander",
                "Wallis and Futuna Islander",
                "Yemeni",
                "Zambian",
                "Zimbabwean"
]
